import { PAGE_SIZE } from "@/utils/constants";
import axios from "axios";
import PaginationData from "@/models/general/PaginationData";
import CustodyExpenseOperationsFilter from "./CustodyExpenseOperationsFilter";
import CustodyExpenseOperationSlice from "./CustodyExpenseOperationSlice";

export default class CustodyExpenseOperation {
  constructor() {
    this.setInitialValue();
  }
  setInitialValue() {
    this.custodyExpenseOperationToken = "";
    this.custodyToken = "";
    this.custodyNameCurrent = "";
    this.custodyExpenseToken = "";
    this.custodyExpenseNameCurrent = "";
    this.moneyPaid = "";
    this.custodyExpenseOperationQuantity = "";
    this.custodyExpenseOperationDescriptionCurrent = "";
    this.custodyExpenseOperationDescriptionEn = "";
    this.custodyExpenseOperationDescriptionAr = "";
    this.custodyExpenseOperationDescriptionUnd = "";
    this.custodyExpenseOperationNotes = "";
    this.userMakeExpenseToken = "";
    this.userMakeActionNameCurrent = "";
    this.userMakeActionCode = "";
    this.userMakeActionImagePath = "";
    this.userMakeActionCountryCodeName = "";
    this.userMakeActionCountryCode = "";
    this.userMakeActionPhone = "";
    this.userMakeActionPhoneWithCC = "";
    this.userMakeActionEmail = "";
    this.dateTimeActionAdd = "";
    this.dateActionAdd = "";
    this.timeActionAdd = "";
    this.expenseCancellationStatus = false;
    this.expenseCancellationReasonCurrent = "";
    this.expenseCancellationReasonEn = "";
    this.expenseCancellationReasonAr = "";
    this.expenseCancellationReasonUnd = "";
    this.expenseCancellationNotes = "";
    this.userMakeExpenseCancellationToken = "";
    this.userMakeExpenseCancellationNameCurrent = "";
    this.userMakeExpenseCancellationCode = "";
    this.userMakeExpenseCancellationCountryCode = "";
    this.userMakeExpenseCancellationCountryCodeName = "";
    this.userMakeExpenseCancellationPhone = "";
    this.userMakeExpenseCancellationPhoneWithCC = "";
    this.userMakeExpenseCancellationEmail = "";
    this.userMakeExpenseCancellationImagePath = "";
    this.expenseCancellationDateTime = "";
    this.expenseCancellationDate = "";
    this.expenseCancellationTime = "";
    this.custodyExpenseOperationArchiveStatus = false;
    this.custodyExpenseOperationsData = [new CustodyExpenseOperationSlice()];
  }
  fillData(data) {
    this.custodyExpenseOperationToken = data.custodyExpenseOperationToken;
    this.custodyToken = data.custodyToken;
    this.custodyNameCurrent = data.custodyNameCurrent;
    this.custodyExpenseToken = data.custodyExpenseToken;
    this.custodyExpenseNameCurrent = data.custodyExpenseNameCurrent;
    this.moneyPaid = data.moneyPaid;
    this.custodyExpenseOperationQuantity = data.custodyExpenseOperationQuantity;
    this.custodyExpenseOperationDescriptionCurrent =
      data.custodyExpenseOperationDescriptionCurrent;
    this.custodyExpenseOperationDescriptionEn =
      data.custodyExpenseOperationDescriptionEn;
    this.custodyExpenseOperationDescriptionAr =
      data.custodyExpenseOperationDescriptionAr;
    this.custodyExpenseOperationDescriptionUnd =
      data.custodyExpenseOperationDescriptionUnd;
    this.custodyExpenseOperationNotes = data.custodyExpenseOperationNotes;
    this.userMakeExpenseToken = data.userMakeExpenseToken;
    this.userMakeActionNameCurrent = data.userMakeActionNameCurrent;
    this.userMakeActionCode = data.userMakeActionCode;
    this.userMakeActionImagePath = data.userMakeActionImagePath;
    this.userMakeActionCountryCodeName = data.userMakeActionCountryCodeName;
    this.userMakeActionCountryCode = data.userMakeActionCountryCode;
    this.userMakeActionPhone = data.userMakeActionPhone;
    this.userMakeActionPhoneWithCC = data.userMakeActionPhoneWithCC;
    this.userMakeActionEmail = data.userMakeActionEmail;
    this.dateTimeActionAdd = data.dateTimeActionAdd;
    this.dateActionAdd = data.dateActionAdd;
    this.timeActionAdd = data.timeActionAdd;
    this.expenseCancellationStatus = data.expenseCancellationStatus;
    this.expenseCancellationReasonCurrent =
      data.expenseCancellationReasonCurrent;
    this.expenseCancellationReasonEn = data.expenseCancellationReasonEn;
    this.expenseCancellationReasonAr = data.expenseCancellationReasonAr;
    this.expenseCancellationReasonUnd = data.expenseCancellationReasonUnd;
    this.expenseCancellationNotes = data.expenseCancellationNotes;
    this.userMakeExpenseCancellationToken =
      data.userMakeExpenseCancellationToken;
    this.userMakeExpenseCancellationNameCurrent =
      data.userMakeExpenseCancellationNameCurrent;
    this.userMakeExpenseCancellationCode = data.userMakeExpenseCancellationCode;
    this.userMakeExpenseCancellationCountryCode =
      data.userMakeExpenseCancellationCountryCode;
    this.userMakeExpenseCancellationCountryCodeName =
      data.userMakeExpenseCancellationCountryCodeName;
    this.userMakeExpenseCancellationPhone =
      data.userMakeExpenseCancellationPhone;
    this.userMakeExpenseCancellationPhoneWithCC =
      data.userMakeExpenseCancellationPhoneWithCC;
    this.userMakeExpenseCancellationEmail =
      data.userMakeExpenseCancellationEmail;
    this.userMakeExpenseCancellationImagePath =
      data.userMakeExpenseCancellationImagePath;
    this.expenseCancellationDateTime = data.expenseCancellationDateTime;
    this.expenseCancellationDate = data.expenseCancellationDate;
    this.expenseCancellationTime = data.expenseCancellationTime;
    this.custodyExpenseOperationArchiveStatus =
      data.custodyExpenseOperationArchiveStatus;
  }

  async getAllCustodyExpenseOperations(
    language,
    userAuthorizeToken,
    pagination = new PaginationData(),
    filterData = new CustodyExpenseOperationsFilter()
  ) {
    try {
      let response = await axios.get(
        `/CustodyExpenseOperations/GetAllCustodyExpenseOperations?language=${language}&userAuthorizeToken=${userAuthorizeToken}&cityArchiveStatus=false&page=${pagination.selfPage}&paginationStatus=true&pageSize=${PAGE_SIZE}&filterStatus=true&custodyToken=${filterData.custodyToken}&custodyExpenseToken=${filterData.custodyExpenseToken}&textSearch=${filterData.textSearch}`
      );
      return response;
    } catch (error) {
      return this.$t("errorCatch");
    }
  }

  async addCustodyExpenseOperation(language, userAuthorizeToken) {
    var data = {
      language: language,
      userAuthorizeToken: userAuthorizeToken,
      custodyToken: this.custodyToken,
      employeeToken: this.employeeToken,
      custodyExpenseOperationsData: this.custodyExpenseOperationsData,
    };

    try {
      return await axios.post(
        `/CustodyExpenseOperations/AddCustodyExpenseOperation`,
        data
      );
    } catch (e) {
      return this.$t("errorCatch");
    }
  }

  async cancelCustodyExpenseOperation(language, userAuthorizeToken) {
    var data = {
      language: language,
      userAuthorizeToken: userAuthorizeToken,
      custodyToken: this.custodyToken,
      // custodyExpenseOperationsData: this.custodyExpenseOperationsData,
      custodyExpenseOperationsData: [
        {
          custodyExpenseOperationToken: this.custodyExpenseOperationToken,
          expenseCancellationReasonEn: this.expenseCancellationReasonEn,
          expenseCancellationReasonAr: this.expenseCancellationReasonAr,
          expenseCancellationReasonUnd: this.expenseCancellationReasonUnd,
          expenseCancellationNotes: this.expenseCancellationNotes,
        },
      ],
    };

    try {
      return await axios.post(
        `/CustodyExpenseOperations/CancelCustodyExpenseOperation`,
        data
      );
    } catch (e) {
      return this.$t("errorCatch");
    }
  }
}
