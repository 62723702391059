export default class CustodyExpenseOperationSlice {
  constructor() {
    this.setInitialValue();
  }
  setInitialValue() {
    // add
    this.custodyExpenseToken = "";
    this.moneyPaid = "";
    this.custodyExpenseOperationQuantity = "";
    this.custodyExpenseOperationDescriptionEn = "";
    this.custodyExpenseOperationDescriptionAr = "";
    this.custodyExpenseOperationDescriptionUnd = "";
    this.custodyExpenseOperationNotes = "";

    // calcel
    this.custodyExpenseOperationToken = "";
    this.expenseCancellationReasonEn = "";
    this.expenseCancellationReasonAr = "";
    this.expenseCancellationReasonUnd = "";
    this.expenseCancellationNotes = "";
  }
  fillData(data) {
    this.custodyExpenseToken = data.custodyExpenseToken;
    this.moneyPaid = data.moneyPaid;
    this.custodyExpenseOperationQuantity = data.custodyExpenseOperationQuantity;
    this.custodyExpenseOperationDescriptionEn =
      data.custodyExpenseOperationDescriptionEn;
    this.custodyExpenseOperationDescriptionAr =
      data.custodyExpenseOperationDescriptionAr;
    this.custodyExpenseOperationDescriptionUnd =
      data.custodyExpenseOperationDescriptionUnd;
    this.custodyExpenseOperationNotes = data.custodyExpenseOperationNotes;

    this.custodyExpenseOperationToken = data.custodyExpenseOperationToken;
    this.expenseCancellationReasonEn = data.expenseCancellationReasonEn;
    this.expenseCancellationReasonAr = data.expenseCancellationReasonAr;
    this.expenseCancellationReasonUnd = data.expenseCancellationReasonUnd;
    this.expenseCancellationNotes = data.expenseCancellationNotes;
  }
}
