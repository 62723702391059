export default class CustodyExpenseOperationsFilter {
  constructor() {
    this.setInitialValue();
  }
  setInitialValue() {
    this.custodyToken = "";
    this.custodyExpenseToken = "";
    this.textSearch = "";
  }
  fillData(data) {
    this.custodyToken = data.custodyToken;
    this.custodyExpenseToken = data.custodyExpenseToken;
    this.textSearch = data.textSearch;
  }
}
